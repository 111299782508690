import * as React from "react";
import { Link } from "gatsby";
import Baseline from "../images/baseline.inline.svg";

const NotFoundPage = () => {
  return (
    <main className=" flex h-screen flex-col items-center justify-center space-y-12 bg-pink font-gotham-ultra text-xl text-white lg:flex-wrap lg:text-4xl">
      <div className="">
        <a href="/">
          <Baseline className="w-64 fill-white stroke-white text-white" />
        </a>
      </div>
      <div className="flex flex-col items-center">
        <p className="pb-12">
          Oups{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          cette page n'existe pas !
          <br />
        </p>
        <Link className="text-yellow" to="/">
          Retour à l'accueil
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
